export enum IconType {
  LOGO_ICON = "LOGO_ICON",
  LEFT_ARROW_ICON = "LEFT_ARROW_ICON",
  RIGHT_ARROW_ICON = "RIGHT_ARROW_ICON",
  HIDDEN_PASSWORD_ICON = "HIDDEN_PASSWORD_ICON",
  COLORFUL_LOGO_ICON = "COLORFUL_LOGO_ICON",
  CONGRATS_ICON = "CONGRATS_ICON",
  INCRASE_ICON = "INCRASE_ICON",
  DECRASE_ICON = "DECRASE_ICON",
  LANGUAGE_ICON = "LANGUAGE_ICON",
  ALERT_ICON = "ALERT_ICON",
  LOCATION_ICON = "LOCATION_ICON",
  UPLOAD_ICON = "UPLOAD_ICON",
  SUCCESSFUL_LOGIN_ICON = "SUCCESSFUL_LOGIN_ICON",
  SUPPORT_ICON = "SUPPORT_ICON",
  CHECK_EMAIL_ICON = "CHECK_EMAIL_ICON",
  DELETE_ICON = "DELETE_ICON",
  SHOW_PASSWORD_ICON = "SHOW_PASSWORD_ICON",
  CRICLE_ICON = "CRICLE_ICON",
  VERTICAL_ARROW_ICON = "VERTICAL_ARROW_ICON",
  CRICLE_COLORFUL_ICON = "CRICLE_COLORFUL_ICON",
  VERTICAL_COLORFUL_ICON = "VERTICAL_COLORFUL_ICON",
  VERIFY_ICON = "VERIFY_ICON",
  DATE_PICKER_ICON = "DATE_PICKER_ICON",
  DRAFT_ICON = "DRAFT_ICON",
  CLOSE_ICON = "CLOSE_ICON",
  EDIT_ICON = "EDIT_ICON",
  VISA_ICON = "VISA_ICON",
  STRIPE_ICON = "STRIPE_ICON",
  MASTER_CARD_ICON = "MASTER_CARD_ICON",
  APPLE_PAY_ICON = "APPLE_PAY_ICON",
  PAYPAL_ICON = "PAYPAL_ICON",
  LOCK_ICON = "LOCK_ICON",
  SENT_EMAIL_ICON = "SENT_EMAIL_ICON",
  PAYMENT_SUCCESSFUL_ICON = "PAYMENT_SUCCESSFUL_ICON",
  RESET_PASSWORD_COMPLETE_ICON = "RESET_PASSWORD_COMPLETE_ICON",
  DOWN_ARROW_ICON = "DOWN_ARROW_ICON",
  UP_ARROW_ICON = "UP_ARROW_ICON",
  DUTCH_FLAG_ICON = "DUTCH_FLAG_ICON",
  OPTIONS_ICON = "OPTIONS_ICON",
  UPGRADE_ICON = "UPGRADE_ICON",
  SEARCH_ICON = "SEARCH_ICON",
  NOTIFICATIONS_ICON = "NOTIFICATIONS_ICON",
  HOME_ICON = "HOME_ICON",
  HR_ICON = "HR_ICON",
  PROJECT_ICON = "PROJECT_ICON",
  TEAM_ICON = "TEAM_ICON",
  EVENTS_ICON = "EVENTS_ICON",
  CUSTOMERS_ICON = "CUSTOMERS_ICON",
  CALENDAR_ICON = "CALENDAR_ICON",
  STATISTIC_ICON = "STATISTIC_ICON",
  INSURANCE_ICON = "INSURANCE_ICON",
  CHAT_ICON = "CHAT_ICON",
  SETTINGS_ICON = "SETTINGS_ICON",
  LOGOUT_ICON = "LOGOUT_ICON",
  PENDING_ICON = "PENDING_ICON",
  TOTAL_CONTRATS_ICON = "TOTAL_CONTRATS_ICON",
  TOTAL_CUSTOMERS_ICON = "TOTAL_CUSTOMERS_ICON",
  TOTAL_EVENTS_ICON = "TOTAL_EVENTS_ICON",
  CLOCK_ICON = "CLOCK_ICON",
  UPLOAD_FILE_ICON = "UPLOAD_FILE_ICON",
  UP_PERCENTAGE_ICON = "UP_PERCENTAGE_ICON",
  DOWN_PERCENTAGE_ICON = "DOWN_PERCENTAGE_ICON",
  VIEW_ICON = "VIEW_ICON",
  CRICLE_CLOSE_ICON = "CRICLE_CLOSE_ICON",
  FILTER_ICON = "FILTER_ICON",
  EXPORT_ICON = "EXPORT_ICON",
  PLUS_ICON = "PLUS_ICON",
  TOOLTIP_THREE_DOTS = "TOOLTIP_THREE_DOTS",
  ATTACHMENT_ICON = "ATTACHMENT_ICON",
  FILTER_BARS_ICON = "FILTER_BARS_ICON",
  GRID_VIEW_ICON = "GRID_VIEW_ICON",
  LIST_VIEW_ICON = "LIST_VIEW_ICON",
  CIRCLE_PLUS_ICON = "CIRCLE_PLUS_ICON",
  DRAG_HANDLE_DOTS_ICON = "DRAG_HANDLE_DOTS_ICON",
  CHECKBOX_TICK_ICON = "CHECKBOX_TICK_ICON",
  MINUS_ICON = "MINUS_ICON",
  THIN_PLUS_ICON = "THIN_PLUS_ICON",
  FILE_PDF_ICON = "FILE_PDF_ICON",
  PROFILE_ICON = "PROFILE_ICON",
  FILE_JPG_ICON = "FILE_JPG_ICON",
  DOCUMENT_ICON = "DOCUMENT_ICON",
  ACTIVITY_LOG_ICON = "ACTIVITY_LOG_ICON",
  ADD_NEW_ICON = "ADD_NEW_ICON",
  CUSTOMER_ACCES_ICON = "CUSTOMER_ACCES_ICON",
  DELETE_SOURCE_ICON = "DELETE_SOURCE_ICON",
  EDIT_SOURCE_ICON = "EDIT_SOURCE_ICON",
  TUTORIAL_CAM_ICON = "TUTORIAL_CAM_ICON",
  ZAPIER_TOOL_ICON = "ZAPIER_TOOL_ICON",
  COPY_ICON = "COPY_ICON",
  CSV_ICON = "CSV_ICON",
  CHECK_ICON = "CHECK_ICON",
  UPLOAD_CSV = "UPLOAD_CSV",
  UPLOADED_CSV_ICON = "UPLOADED_CSV_ICON",
  DELETE_CAMPAIGN_FIELDS_ICON = "DELETE_CAMPAIGN_FIELDS_ICON",
  BACK_BUTTON_MODAL = "BACK_BUTTON_MODAL",
  CHECK_2_ICON = "CHECK_2_ICON",
  CHECK_3_ICON = "CHECK_3_ICON",
  LEADS_ICON = "LEADS_ICON",
  CLOSE_X_SM_ICON = "CLOSE_X_SM_ICON",
  LOST_LEADS_ICON = "LOST_LEADS_ICON",
  CONVERSION_RATE_ICON = "CONVERSION_RATE_ICON",
  KRANKENKASSE_ICON = "KRANKENKASSE_ICON",
  RECHTSSCHUTZ_ICON = "RECHTSSCHUTZ_ICON ",
  AUTO_ICON = "AUTO_ICON",
  VORSORGE_ICON = "VORSORGE_ICON",
  SACHEN_ICON = "SACHEN_ICON",
  EMAIL_ICON = "EMAIL_ICON",
  PHONE_ICON = "PHONE_ICON",
  ASSIGNED_ICON = "ASSIGNED_ICON",
  EMPLOYEE_EMAIL_ICON = "EMPLOYEE_EMAIL_ICON",
  EMPLOYEE_JOB_ICON = "EMPLOYEE_JOB_ICON",
  DOWNLOAD_ICON = "DOWNLOAD_ICON",
  TIME_ICON = "TIME_ICON",
  RADIO_BUTTON_ICON = "RADIO_BUTTON_ICON",
  RATE_STAR_ICON = "RATE_STAR_ICON",
  WON_APPOINTMENT_ICON = "WON_APPOINTMENT_ICON",
  LOST_APPOINTMENT_ICON = "LOST_APPOINTMENT_ICON",
  PENDING_APPOINTMENT_ICON = "PENDING_APPOINTMENT_ICON",
  FOLLOW_UP_APPOINTMENT_ICON = "FOLLOW_UP_APPOINTMENT_ICON",
  CONGRATS_APPOINTMET_ICON = "CONGRATS_APPOINTMET_ICON",
  UPLOAD_IMAGE_COLORFUL_ICON = "UPLOAD_IMAGE_COLORFUL_ICON",
  APPOINTMENT_CALENDAR_ICON = "APPOINTMENT_CALENDAR_ICON",
  TRANSFER_ICON = "TRANSFER_ICON",
  CONVERT_ICON = "CONVERTE_ICON",
  CAMERA_ICON = "CAMERA_ICON",
  APPROVED_ICON = "APPROVED_ICON",
  SIGNED_CONTRACT = "SIGNED_CONTRACT",
  PRODUCTS_ICON = "PRODUCTS_ICON",
  PDFUPLOAD_ICON = "PDFUPLOAD_ICON",
  KVG_ICON = "KVG_ICON",
  LAW_PROTECTION_ICON = "LAW_PROTECTION_ICON",
  CAR_INSURANCE_ICON = "CAR_INSURANCE_ICON",
  CUSTOMER_PENDING_ICON = "CUSTOMER_PENDING_ICON",
  PRINT_ICON = "PRINT_ICON",
  LOST_CUSTOMER_ICON = "LOST_CUSTOMER_ICON",
  ATTENDANCE_ICON = "ATTENDANCE_ICON",
  LEAVE_ICON = "LEAVE_ICON",
  PROFILE_DOWNLOAD_ICON = "PROFILE_DOWNLOAD_ICON",
  ACCOUNT_ACCESS_ICON = "ACCOUNT_ACCESS_ICON",
  PROFESIONAL_INFORMATION_ICON = "PROFESIONAL_INFORMATION_ICON",
  PARTNERS_ICON = "PARTNERS_ICON",
  TOTAL_PARTNERS_ICON = "TOTAL_PARTNERS_ICON",
  NEW_PARTNER_ICON = "NEW_PARTNER_ICON",
  ARCHIVED_PARTNERS_ICON = "ARCHIVED_PARTNERS_ICON",
  DELETE_PARTNERS_ICON = "DELETE_PARTNERS_ICON",
  RELATED_CUSTOMER_ICON = "RELATED_CUSTOMER_ICON",
  DOTS_ICON = "DOTS_ICON",
  MARK_AS_READ_ICON = "MARK_AS_READ_ICON",
  FLAG_ICON = "FLAG_ICON",
  PENDINGS_ICON = "PENDINGS_ICON",
  OPEN_PENDINGS_ICON = 'OPEN_PENDINGS_ICON',
  PROCESSING_PENDINGS_ICON = 'PROCESSING_PENDINGS_ICON',
  DONE_PENDING_ICON = 'DONE_PENDING_ICON',
  AUTO_GENERATE_PASSWORD_ICON = 'AUTO_GENERATE_PASSWORD_ICON',
  CHF_CURRENCY_ICON = "CHF_CURRENCY_ICON",
  COMMENT_ICON = "COMMENT_ICON",
  EMOJI_ICON = "EMOJI_ICON",
  DOWNLOAD_PDF_ICON = "DOWNLOAD_PDF_ICON",
  ASSIGN_LEAD_ICON = "ASSIGN_LEAD_ICON",
}
