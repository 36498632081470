import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import UpdatePendings from "./UpdatePendings";
import UploadFile from "./UploadFile";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useStoreNewCustomerDocument } from "lib/hooks/mutations/Pendings/useStoreNewDocument";
import IconButton from "components/atoms/button/IconButton";
import { useShowPendingDocument } from "lib/hooks/queries/Pendings/useShowPendingDocument";
import ErrorState from "components/atoms/error-state/ErrorState";
import { useSendFeedback } from "lib/hooks/mutations/Pendings/useSendFeedback";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import TextArea from "components/atoms/text-area/TextArea";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import Picker from "@emoji-mart/react";
import { useSendComment } from "lib/hooks/mutations/Pendings/useSendComment";
import { formatDistanceToNow } from "date-fns";
import data from "@emoji-mart/data";

export interface IUpdatePendingsProcessProps<T> {
  updateCurrentPending: number;
  setCurrentUpdatePending: Dispatch<SetStateAction<number>>;
  userData?: T;
  isLoadingFetchingUser?: boolean;
  setIsUpdatePendingModalOpen: (value: boolean) => void;
  showToCommentsConfirm: boolean;
  showToCommentsBackButton: boolean;
}

const UpdatePendingsProcess: FC<IUpdatePendingsProcessProps<any>> = ({
  updateCurrentPending,
  setCurrentUpdatePending,
  userData,
  isLoadingFetchingUser,
  setIsUpdatePendingModalOpen,
  showToCommentsBackButton,
  showToCommentsConfirm
}) => {
  const [documentData, setDocumentData] = useState<any>(null);
  const [isHover, setIsHover] = useState(false);
  const { themeColors } = useThemeContext();
  const [product, setProduct] = useState<any>(null);
  const [viewFileUrl, setViewFileUrl] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<any>("");
  const [fileName, setFileName] = useState<string | null>("");
  const hasPermissions = usePermissions([
    P["Costumer Admin"],
    P["Costumer Menager"]
  ]);
  const [fileSize, setFileSize] = useState<string | null>("");
  const token: any = localStorage.getItem("token");
  const [imagePreview, setImagePreview] = useState<any>();
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [imageBlob, setImageBlob] = useState(null);
  const { mutate: sendFeedback } = useSendFeedback();
  const { mutateAsync: storeDocument, isLoading: isStoreLoading } =
    useStoreNewCustomerDocument();
  const [showAttachmentByIndex, setShowAttachmentByIndex] = useState<any>();
  const { refetch: refetchDocument, isError: hasDocumentError } =
    useShowPendingDocument(documentData?.file);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { mutate: sendComment } = useSendComment();
  const [comment, setComment] = useState<string>("");

  const handleEmojiSelect = (emoji: any) => {
    setComment((prevComment) => prevComment + emoji.native);
    setShowEmojiPicker(false);
  };

  console.log("userData", isLoadingFetchingUser);

  const onSubmit = async (): Promise<void> => {
    const formData = new FormData();
    formData.append("document", uploadedFile);
    formData.append("product", product.product);
    formData.append("document_type", documentData?.name);
    formData.append("pending_id", userData.data.id);
    try {
      await storeDocument(formData);
      setCurrentUpdatePending(1);
      setUploadedFile("");
      setFileName("");
      setFileSize("");
    } catch (error) {
      setUploadedFile("");
      setFileName("");
      setFileSize("");
    }
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setImageBlob(file);
    }
  };

  const handleSendComment = () => {
    const formData = new FormData();
    formData.append("pending_id", userData?.data?.id);
    formData.append("comment", comment);

    if (imageBlob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formData.append("image", reader.result as string);
        sendComment(formData);
        setComment("");
        setImagePreview(null);
        setImageBlob(null);
      };
      reader.readAsDataURL(imageBlob);
    } else {
      sendComment(formData);
      setComment("");
      setImagePreview(null);
      setImageBlob(null);
    }
  };

  useEffect(() => {
    if (imagePreview) {
      setTextareaHeight("250px");
    } else {
      setTextareaHeight("auto");
    }
  }, [imagePreview]);

  const handleRemoveImage = () => {
    setImagePreview(null);
    setImageBlob(null);
  };

  return (
    <div>
      <ConditionalRender condition={updateCurrentPending === 1}>
        <UpdatePendings
          setDocumentData={setDocumentData}
          setProduct={setProduct}
          setCurrentUpdatePending={setCurrentUpdatePending}
          isLoadingFetchingUser={isLoadingFetchingUser}
          userData={userData}
          setIsUpdatePendingModalOpen={setIsUpdatePendingModalOpen}
          themeColors={themeColors}
          refetchDocument={refetchDocument}
        />
      </ConditionalRender>
      <ConditionalRender condition={updateCurrentPending === 2}>
        <UploadFile
          uploadedFile={uploadedFile}
          viewFileUrl={viewFileUrl}
          fileSize={fileSize}
          setUploadedFile={setUploadedFile}
          storeDocument={storeDocument}
          fileName={fileName}
          isStoreLoading={isStoreLoading}
          setFileSize={setFileSize}
          setFileName={setFileName}
          setCurrentUpdatePending={setCurrentUpdatePending}
          setViewFileUrl={setViewFileUrl}
          documentData={documentData}
          product={product}
          userData={userData}
        />
      </ConditionalRender>
      <ConditionalRender condition={updateCurrentPending === 3}>
        <div>
          {viewFileUrl && (
            <div style={{ height: "100%" }}>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={viewFileUrl}
                  renderLoader={() => (
                    <div className="min-h-[500px]">
                      <LoadingScreen />
                    </div>
                  )}
                />
              </Worker>
              <Button
                onClick={() => setCurrentUpdatePending(2)}
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
              >
                <Icon
                  iconType={IconType.BACK_BUTTON_MODAL}
                  className="!w-[30px] !h-[30px]"
                  color={themeColors.primary}
                />
                {documentData?.name ?? ""}
              </Button>
              <div className="flex justify-end">
                <IconButton
                  type="submit"
                  onClick={onSubmit}
                  disabled={isStoreLoading ? true : false}
                  icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
                >
                  Upload
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </ConditionalRender>
      <ConditionalRender condition={updateCurrentPending === 4}>
        {(documentData?.file as any) && (
          <div style={{ height: "100%" }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                renderError={(error) => (
                  <>
                    <ErrorState error={hasDocumentError}>
                      Something went wrong. Please try again later.
                    </ErrorState>
                  </>
                )}
                fileUrl={`https://mainhub.insusales.com/api/costumers/pendings/show-file/${documentData?.file}`}
                renderLoader={() => (
                  <div className="min-h-[500px]">
                    <LoadingScreen />
                  </div>
                )}
                httpHeaders={{
                  Authorization: `Bearer ${token}`
                }}
              />
            </Worker>
            <>
              <Button
                onClick={() => setCurrentUpdatePending(1)}
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
              >
                <Icon
                  iconType={IconType.BACK_BUTTON_MODAL}
                  className="!w-[30px] !h-[30px]"
                  color={themeColors.primary}
                />
                {documentData?.name ?? ""}
              </Button>
              <ConditionalRender
                condition={
                  hasPermissions &&
                  documentData.status !== "rejected" &&
                  documentData.status !== "approved"
                }
              >
                {" "}
                <div className="flex justify-end gap-[26px] mt-5">
                  <IconButton
                    onClick={() => {
                      setCurrentUpdatePending(5);
                    }}
                    icon={<Icon iconType={IconType.CLOSE_ICON} color="white" />}
                    className="!bg-[#D61616]"
                  >
                    Reject
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      sendFeedback({
                        pending_id: userData.data.id,
                        feedback: "approved",
                        document_type: documentData?.name,
                        product: product?.product
                      });
                      setCurrentUpdatePending(1);
                    }}
                    icon={
                      <Icon iconType={IconType.APPROVED_ICON} color="white" />
                    }
                    className="!bg-[#2CC95B]"
                  >
                    Approve
                  </IconButton>
                </div>
              </ConditionalRender>
            </>
          </div>
        )}
      </ConditionalRender>
      <ConditionalRender condition={updateCurrentPending === 5}>
        <>
        {isLoadingFetchingUser && <LoadingScreen />}
          <InputsFolder title="Comments">
            <div>
              <div className="mb-0 flex flex-col gap-7 w-full">
                {showToCommentsBackButton ? (
                  <Button
                    onClick={() => setCurrentUpdatePending(1)}
                    type="button"
                    className="absolute top-[26px] left-[30px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
                  >
                    <Icon
                      iconType={IconType.BACK_BUTTON_MODAL}
                      className="!w-[30px] !h-[30px]"
                      color={themeColors.primary}
                    />
                    Comment Reason Of Rejection : {documentData?.name ?? ""}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="absolute top-[26px] left-[30px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
                  >
                    {documentData?.name ?? "Comments"}
                  </Button>
                )}
              </div>
              <div className="border-[#D0D5DD] bg-white border-[1px] rounded-[8px] relative">
                <div
                  className="relative"
                  style={{ height: textareaHeight }}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <TextArea
                    onChange={(e) => setComment(e.target.value)}
                    className="!border-none bg-transparent"
                    value={comment}
                    style={{ height: "100%", resize: "none" }}
                  />
                  <div className="absolute flex items-center gap-[6px] bottom-[10px] left-[20px]">
                    <div onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                      <Icon iconType={IconType.EMOJI_ICON} />
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                      id="image-upload"
                    />
                    <label htmlFor="image-upload">
                      <Icon iconType={IconType.ATTACHMENT_ICON} />
                    </label>
                  </div>
                  <div
                    className={`${
                      textareaHeight ? "top-[13rem]" : ""
                    } absolute z-[999]`}
                  >
                    {showEmojiPicker && (
                      <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                    )}
                  </div>
                  <IconButton
                    type="button"
                    onClick={handleSendComment}
                    className="w-[100px] h-[40px] flex items-center justify-center absolute bottom-[10px] right-[20px]"
                  >
                    Send
                  </IconButton>
                  {imagePreview && (
                    <div className="absolute bottom-[60px] left-[20px]">
                      <img
                        src={imagePreview}
                        onMouseEnter={() => setIsHover(true)}
                        alt="Preview"
                        className="w-[100px] h-[100px] object-cover rounded-[8px]"
                      />
                      {isHover && (
                        <Button
                          type="button"
                          onClick={handleRemoveImage}
                          className="absolute top-[3px] right-[5px]"
                        >
                          <Icon
                            iconType={IconType.CLOSE_ICON}
                            className="w-[15px] h-[15px] p-[2px] bg-white rounded-full"
                          />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <ConditionalRender
                condition={userData?.data?.comments?.length > 0}
              >
                <div className="mt-[24px]">
                  {userData?.data?.comments?.map?.(
                    (item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col gap-[12px] mb-[24px]"
                        >
                          <div className="flex items-center gap-[10px]">
                            <img alt=""
                              className="w-[30px] h-[30px] rounded-full object-cover"
                              src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                            />
                            <p className="font-inter-medium text-[#282D46] text-[15px]">
                              {item?.user_id}
                            </p>
                          </div>
                          <div className="pl-[5px]">
                            <p className="text-[#282D46] capitalize text-[14px] font-inter-regular">
                              {item.comment}
                            </p>
                            {item.image &&
                            showAttachmentByIndex === item?.image ? (
                              <img alt=""
                                src={`https://mainhub.insusales.com/api/costumers/pendings/show-comment-img/${item?.image}`}
                                className="w-[100px] h-[100px] mt-[12px] object-cover cursor-pointer"
                                onClick={() => {
                                  window.open(
                                    `https://mainhub.insusales.com/api/costumers/pendings/show-comment-img/${item?.image}`,
                                    "_blank"
                                  );
                                }}
                              />
                            ) : null}
                            <div className="mt-[12px] flex items-center gap-[12px]">
                              <p className="text-[#7D8592] text-[12px] font-inter-regular">
                                {formatDistanceToNow(
                                  new Date(item.created_at),
                                  {
                                    addSuffix: true
                                  }
                                )}
                              </p>
                              <Button
                                type="button"
                                disabled={!item.image}
                                onClick={() =>
                                  setShowAttachmentByIndex(item?.image)
                                }
                                className="text-[#7D8592] text-[12px] font-inter-regular flex item-center gap-[6px]"
                              >
                                <Icon
                                  iconType={IconType.ATTACHMENT_ICON}
                                  className="w-[14px] h-[14px]"
                                />{" "}
                                {item?.image ? "1" : "0"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </ConditionalRender>
            </div>
          </InputsFolder>
          <div className="flex justify-end my-[20px]">
            {showToCommentsConfirm && (
              <IconButton
                onClick={() => {
                  sendFeedback({
                    pending_id: userData.data.id,
                    feedback: "rejected",
                    document_type: documentData?.name,
                    product: product?.product
                  });
                  setCurrentUpdatePending(1);
                }}
              >
                Confirm
              </IconButton>
            )}
          </div>
        </>
      </ConditionalRender>
    </div>
  );
};

export default withModal(UpdatePendingsProcess);
