import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check2Icon from "components/atoms/icon/icon-type/Check2Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import Creatable from "react-select/creatable";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useDataOfCsv } from "lib/hooks/mutations/Campaign/useDataOfCsv";
import { FC, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { customStyles } from "lib/helpers/configs/customStyles";
import Button from "components/atoms/button/Button";
import Input from "components/atoms/input/Input";

export interface IStep4Props {
  setStep: any;
  themeColors: {
    primary: string;
  };
  state: any;
  setState: (state: any) => void;
}

const fields = [
  { label: "First Name*", value: "first_name", required: true },
  { label: "Last Name*", value: "last_name", required: true },
  { label: "Nationality", value: "nationality" },
  { label: "Date Of Birth", value: "birthdate" },
  { label: "Current Health Insurance", value: "health_insurance" },
  { label: "Persons in Household", value: "persons" },
  { label: "Language", value: "language" },
  { label: "Phone*", value: "phone", required: true },
  { label: "Email", value: "email" },
  { label: "Street", value: "address" },
  { label: "Nr", value: "nr" },
  { label: "PLZ", value: "postal_code" },
  { label: "City", value: "city" },
];

const Step4: FC<IStep4Props> = ({ setStep, themeColors, state, setState }) => {
  const { formData } = useMultiStepFormContext();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    setError,
    watch,
    trigger,
    register,
    reset,
    clearErrors,
  } = useForm();

  const headers = (formData as { headers: string[] })?.headers;
  const { mutateAsync: dataCsv, isLoading } = useDataOfCsv();

  const options = headers?.map?.((header: any) => ({
    label: header,
    value: header?.toLowerCase().replace(/\s+/g, "_"),
  }));

  const onSelectChange = (fieldName: string, selectedIndex: number) => {
    if (!fieldName) return;
    setValue(String(fieldName), selectedIndex);
  };

  const onSubmit = async (data: any) => {
    const { campaing: { id: campaing_id } = {} } = formData as {
      campaing: { id: string };
    };

    const fileFromFormData = formData as any;

    state.map((item: any) => {
      return (
        extraFields[item?.data?.field_name] = {
          value: watch(item?.data?.field_name),
          default_text: item?.data?.default_text,
          required: item?.data?.required ? 1 : 0,
          section: item?.data?.section,
          field_type: item?.data?.field_type
        }
      )
    });
    const extraFieldNames = Object.keys(extraFields);

    // Destructure these fields out of data
    let dataWithoutExtraFields = { ...data };
    extraFieldNames.forEach((fieldName) => {
      delete dataWithoutExtraFields[fieldName];
    });

    const extraFieldsArray = Object.keys(extraFields).map((key) => ({
      section: extraFields[key].section,
      field_name: key,
      field_value: extraFields[key].value,
      field_type: extraFields[key].field_type
    }));
    const updatedData = {
      ...data,
      campaing_id,
      file: fileFromFormData.file,
      extra_fields: extraFieldsArray
    };

    dataCsv(updatedData)
      .then(() => {
        setStep(5);
      })
      .catch(() => { });
  };

  const customSelectRefs = useRef<(Creatable | null)[]>([]);

  const handleReset = async () => {
    customSelectRefs.current.forEach((ref: any) => ref?.clearValue());

    reset();

    const fieldNames = fields.map((field) => field.value);
    await trigger(fieldNames);

    fields.forEach((field) => {
      if (field?.required) {
        setError(field?.value, {
          type: "manual",
          message: `${field?.label} is required`,
        });
      }
    });
  };

  let extraFields: { [key: string]: any } = {};

  const handleDeleteField = (index: number) => {
    const updatedState = state.filter((_: any, i: number) => i !== index);
    setState(updatedState);
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col pl-4 pb-2"
    >
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5">
          Upload CSV
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          Only adjust the fields you want to import
        </div>
        <div
          className="rounded-[20px] p-7 grid grid-cols-1 lg:grid-cols-2 gap-4 !pb-[40px]"
          style={{ backgroundColor: `${themeColors.primary}0D` }}
        >
          {fields?.map?.((field, index) => (
            <div key={index}>
              <div>
                <Label className="capitalize">{field?.label}</Label>
              </div>
              <div className="flex flex-row items-center gap-2 relative">
                <div className="flex-1">
                  <Controller
                    name={field?.value}
                    rules={{ required: field.required }}
                    control={control}
                    render={({ field }) => {
                      const selectedOption =
                        options?.find(
                          (option) => option?.value === field?.value
                        ) || null;

                      return (
                        <Creatable
                          {...field}
                          ref={(el: any) =>
                            (customSelectRefs.current[index] = el)
                          }
                          placeholder="Choose Field Name"
                          options={options}
                          value={selectedOption?.label}
                          className="cursor-pointer !font-inter-regular react-select-container"
                          classNamePrefix="react-select"
                          isValidNewOption={() => false}
                          onChange={(selectedOption) => {
                            const selectedIndex =
                              options &&
                              options?.findIndex?.(
                                (option) =>
                                  option?.value === selectedOption?.value
                              );
                            field.onChange(
                              selectedOption ? selectedOption?.value : null
                            );
                            clearErrors(field.name);
                            onSelectChange(field.name, selectedIndex);
                          }}
                          styles={customStyles}
                          menuPosition={"fixed"}
                          menuPortalTarget={document.body}
                        />
                      );
                    }}
                  />
                  {errors[field.value] && (
                    <p className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px] left-[2px]">{`${field.label} is required`}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
          <>
            {state?.map((item: any, index: number) => {
              if (
                item?.data?.field_type === "text" ||
                item?.data?.field_type === "number"
              ) {
                return (
                  <div className="flex items-center gap-2">
                    <Input
                      key={index}
                      label={item?.data?.field_name}
                      type={
                        item?.data?.field_type === "text" ? "text" : "number"
                      }
                      {...register(item?.data?.field_name, {
                        required: "This is field is required"
                      })}
                      required={!!item?.data?.required}
                      error={errors?.[item?.data?.field_name]}
                      classes={{ container: '!w-full' }}
                    />
                    <Button onClick={() => handleDeleteField(index)} className="mt-[29px]">
                      <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
                    </Button>
                  </div>
                );
              } else if (item?.data?.field_type === "select") {
                return (
                  <div className="relative">
                    <Label text={item.data.field_name} />
                    <Controller
                      key={index}
                      name={item?.data?.field_name || ""}
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field, fieldState }) => {
                        const selectedOption =
                          options?.find(
                            (option) => option?.value === field?.value
                          ) || null;

                        return (
                          <div className="flex items-center gap-2">
                            <Creatable
                              {...field}
                              ref={(el: any) =>
                                (customSelectRefs.current[index as any] = el)
                              }
                              placeholder="Choose Field Name"
                              options={options}
                              value={selectedOption?.label}
                              className="cursor-pointer !font-inter-regular react-select-container w-full"
                              classNamePrefix="react-select"
                              isValidNewOption={() => false}
                              onChange={(selectedOption) => {
                                const selectedIndex = options?.findIndex(
                                  (option) =>
                                    option?.value === selectedOption?.value
                                );
                                field.onChange(
                                  selectedOption
                                    ? selectedOption?.value
                                    : null
                                );
                                clearErrors(field.name);
                                onSelectChange(field.name, selectedIndex);
                              }}
                              styles={customStyles}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                            {fieldState.error && (
                              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px] left-[2px]">
                                {fieldState.error.message}
                              </div>
                            )}
                            <Button onClick={() => handleDeleteField(index)}>
                              <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </div>
                );
              }
              return null;
            })}
          </>
          {isLoading && (
            <LoadingScreen className="!h-full w-fit left-[2px] top-[20px] !z-0" />
          )}{" "}

          <Button
            type="button"
            onClick={() => setStep(8)}
            className={`absolute bottom-[47px] right-[10px]  flex items-center gap-2  font-inter-medium text-[14px] text-[#282D46] ${isLoading ? "pointer-events-none z-[-99]" : ""
              }`}
          >
            <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
            Add Field
          </Button>
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <Check2Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="pl-7 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 opacity-40">
          Remove the leads here that you don't want to import
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 opacity-40">
          <Check3Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="flex gap-5 justify-end">
        <IconButton
          secondary={true}
          type="button"
          onClick={() => {
            handleReset();
          }}
          className={`w-full max-w-[150px] text-[${themeColors.primary}]`}
        >
          Reset
        </IconButton>
        <IconButton
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          className={`w-full max-w-[150px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          disabled={isLoading ? true : false}
        >
          {isLoading ? "Processing..." : "Continue"}
        </IconButton>
      </div>
    </Form>
  );
};

export default Step4;
