import { FC, HTMLAttributes } from "react";

export interface IHeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  title: string;
}

const Headline: FC<IHeadlineProps> = ({ title, ...rest }) => {
  return (
    <h1
      className={`${rest.className} mt-[46px] text-[#282D46]  font-inter-semibold  md:text-[26px]  text-[36px] mb-[33px]`}
    >
      {title}
    </h1>
  );
};

export default Headline;
