import Card from "components/molecules/card/Card";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import TypeBoard from "components/molecules/type-board/TypeBoard";
import { CardType } from "lib/helpers/constants/cardTypes";
import { FC, useState } from "react";
import UpdateLead from "../update-lead/UpdateLead";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import CreateLeadProcces from "../create-lead/CreateLeadProcces/CreateLeadProcces";

export interface ILeadsGridViewProps<T> {
  data: T;
  isAssignLeadsActive: string;
}

const LeadsGridView: FC<ILeadsGridViewProps<any>> = ({
  data,
  isAssignLeadsActive,
}) => {
  const [newLeadModalCurrentStep, setNewLeadModalCurrentStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [updateModalCurrentStep, setUpdateModalCurrentStep] =
    useState<number>(1);

  const openCreateModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="overflow-x-auto horizontalOverflowScrollStyle">
        <div className="flex gap-5 flex-row flex-nowrap ">
          <div className="flex-1">
            <TypeBoard
              openCreateModal={openCreateModal}
              borderColor="#DE92EB"
              bgColor="#DE92EB08"
              data={data
                ?.filter((item: any) => item.feedback_status === "Unprocessed")
                .map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setUpdateModalCurrentStep(1);
                      setIsUpdateModalOpen(true);
                      setUpdateData(item);
                    }}
                    className="cursor-pointer"
                    key={item.id}
                  >
                    <Card<IInfoCardProps>
                      cardType={CardType.LEAD_CARD}
                      {...(item?.info ?? [])}
                      {...item}
                    />
                  </div>
                ))}
              title="Unprocessed"
            />
          </div>
          <div className="flex-1">
            <TypeBoard
              openCreateModal={openCreateModal}
              borderColor="#CC7272"
              bgColor="#CC727208"
              data={data
                ?.filter((item: any) => item.feedback_status === "Not Reached")
                .map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setUpdateModalCurrentStep(1);
                      setIsUpdateModalOpen(true);
                      setUpdateData(item);
                    }}
                    className="cursor-pointer"
                    key={item.id}
                  >
                    <Card<IInfoCardProps>
                      cardType={CardType.LEAD_CARD}
                      {...item?.info}
                      {...item}
                    />
                  </div>
                ))}
              title="Not reached"
            />
          </div>
          <div className="flex-1">
            <TypeBoard
              openCreateModal={openCreateModal}
              borderColor="#EFBE12"
              bgColor="#EFBE1208"
              data={data
                ?.filter((item: any) => item.feedback_status === "In Contact")
                .map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setUpdateModalCurrentStep(1);
                      setIsUpdateModalOpen(true);
                      setUpdateData(item);
                    }}
                    className="cursor-pointer"
                    key={item.id}
                  >
                    <Card<IInfoCardProps>
                      cardType={CardType.LEAD_CARD}
                      {...item?.info}
                      {...item}
                    />
                  </div>
                ))}
              title="In contact"
            />
          </div>
          <div className="flex-1">
            <TypeBoard
              openCreateModal={openCreateModal}
              borderColor="#70DDE4"
              bgColor="#70DDE408"
              data={data
                ?.filter((item: any) => item.feedback_status === "Appointment")
                .map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setUpdateModalCurrentStep(1);
                      setIsUpdateModalOpen(true);
                      setUpdateData(item);
                    }}
                    className="cursor-pointer"
                    key={item.id}
                  >
                    <Card<IInfoCardProps>
                      cardType={CardType.LEAD_CARD}
                      {...item?.info}
                      {...item}
                    />
                  </div>
                ))}
              title="Appointment"
            />
          </div>
          <div className="flex-1">
            <TypeBoard
              openCreateModal={openCreateModal}
              borderColor="#2DE28B"
              bgColor="#2DE28B08"
              data={data
                ?.filter((item: any) => item.feedback_status === "New Customer")
                .map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setUpdateModalCurrentStep(1);
                      setIsUpdateModalOpen(true);
                      setUpdateData(item);
                    }}
                    className="cursor-pointer"
                    key={item.id}
                  >
                    <Card<IInfoCardProps>
                      cardType={CardType.LEAD_CARD}
                      {...item?.info}
                      {...item}
                    />
                  </div>
                ))}
              title="New customer"
            />
          </div>
        </div>
      </div>
      <ConditionalRender condition={isOpen}>
        <CreateLeadProcces
          setHocCurrentStep={setNewLeadModalCurrentStep}
          isAssignLeadsActive={isAssignLeadsActive}
          hocCurrentStep={newLeadModalCurrentStep}
          onConfirm={() => setIsOpen(false)}
          setFormCurrentStep={setNewLeadModalCurrentStep}
          formCurrentStep={newLeadModalCurrentStep}
          hocisOpen={isOpen}
          hocToggleModal={() => setIsOpen(false)}
          hocTitle={
            newLeadModalCurrentStep === 1
              ? "New Lead"
              : newLeadModalCurrentStep === 2
              ? ""
              : ""
          }
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              newLeadModalCurrentStep === 1
                ? "md:min-w-[750px] md-max-w-full lg:max-w-[900px] w-full"
                : newLeadModalCurrentStep === 2
                ? "!w-[462px]"
                : ""
            }`}`,
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUpdateModalOpen}>
        <UpdateLead
          setHocCurrentStep={setUpdateModalCurrentStep}
          hocCurrentStep={updateModalCurrentStep}
          onConfirm={() => setIsUpdateModalOpen(false)}
          setFormCurrentStep={setUpdateModalCurrentStep}
          formCurrentStep={updateModalCurrentStep}
          hocisOpen={isUpdateModalOpen}
          hasChanges={hasChanges}
          hocToggleModal={() => setIsUpdateModalOpen(false)}
          setHasChanges={setHasChanges}
          hocHasChanges={hasChanges}
          hocTitle={
            updateModalCurrentStep === 1
              ? ""
              : updateModalCurrentStep === 2
              ? ""
              : ""
          }
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              updateModalCurrentStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
                : updateModalCurrentStep === 2
                ? "!w-[462px]"
                : updateModalCurrentStep === 3
                ? "text-[100px]  md:min-w-[570px] lg:min-w-[563px] w-full"
                : ""
            }`}`,
          }}
          lead={updateData}
        />
      </ConditionalRender>
    </>
  );
};

export default LeadsGridView;
