import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Table from "components/molecules/table/Table";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useStoreLeads } from "lib/hooks/mutations/Campaign/useStoreLeads";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "react-table";
import { Fragment } from "react/jsx-runtime";

export interface IStep5Props {
  setStep: any;
  dataCols: any;
  onUpdateData: (updatedData: any[]) => void;
  themeColors: {
    primary: string;
  };
}

const Step5: FC<IStep5Props> = ({
  setStep,
  dataCols,
  onUpdateData,
  themeColors,
}) => {
  const { handleSubmit } = useForm();
  const {
    formData,
    clearFormData,
  }: { formData: { data: any[] }; clearFormData: () => void } =
    useMultiStepFormContext();
  const { mutateAsync: storeLead, isLoading } = useStoreLeads();
  // eslint-disable-next-line
  const [_, setStoredCampaign] = useState<string[]>();
  const [tableData, setTableData] = useState(formData.data || []);

  useEffect(() => {
    setTableData(formData.data);

    // eslint-disable-next-line
  }, []);

  const handleRemove = (index: number) => {
    const newData = tableData.filter((_: any, i: number) => i !== index);
    setTableData(newData);
    setStoredCampaign(newData);
    onUpdateData(newData);
  };

  const actionsColumn = {
    Header: "Delete",
    accessor: "delete",
    Cell: ({ row }: any) => (
      <Button
        type="button"
        onClick={() => handleRemove(row.index)}
        className="text-red-600 hover:text-red-800"
      >
        <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
      </Button>
    ),
  };
  const columns = useMemo<Column<any>[]>(
    () => [actionsColumn, ...dataCols.filter((col: any) => col.isChecked)],
    // eslint-disable-next-line
    [dataCols, tableData]
  ) as Column<object>[];

  const onSubmit = () => {
    const { campaing: { id: campaing_id } = {} } = formData as unknown as {
      campaing: { id: string };
    };
    const updatedData = { leads: tableData, campaing_id };
    storeLead(updatedData)
      .then(() => {
        setStep(4);
        clearFormData();
      })
      .catch(() => {});
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-0 flex flex-col pl-4 pb-2"
      >
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
          <div className="pl-2 text-[14px] font-inter-medium pb-5">
            Upload CSV
          </div>
          <div className="absolute top-[0] left-[-12px] z-9999">
            <CheckIcon color={themeColors.primary} />
          </div>
        </div>
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
          <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
            Only adjust the fields you want to import
          </div>

          <div className="absolute top-[0] left-[-12px] z-9999 ">
            <CheckIcon color={themeColors.primary} />
          </div>
        </div>
        <div className="pl-7 relative bg-transparent">
          <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
            Remove the leads here that you don't want to import
          </div>

          <div
            className={`rounded-[20px] p-4 w-full `}
            style={{
              backgroundColor: `${themeColors.primary}0D`,
            }}
          >
            <div className="overflow-auto w-full h-full px-5 horizontalOverflowScrollStyle">
              <Fragment>
                <Table
                  style={{
                    backgroundColor: `${themeColors.primary}0D`,
                  }}
                  classes={{ subContainer: "!p-0 !rounded-none" }}
                  columns={columns}
                  data={tableData || []}
                  search={""}
                  isColumnSorted={true}
                />
              </Fragment>
            </div>
          </div>

          <div className="absolute top-[0] left-[-12px] z-9999 ">
            <Check3Icon color={themeColors.primary} />
          </div>
        </div>
        <div className="flex gap-5 justify-end pt-5">
          <IconButton
            icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
            className={`w-full max-w-[150px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          >
            Continue
          </IconButton>
        </div>
        {isLoading && <LoadingScreen />}
      </Form>
    </>
  );
};

export default Step5;
