import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import {
  EmailIcon,
  LocationIcon,
  PhoneIcon,
} from "components/atoms/icon/export";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import CounterControl from "components/molecules/counter-control-append/CounterControlAppend";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useAppointmentForm } from "lib/hooks/forms/useAppointmentForm";
import { useCreateAppointments } from "lib/hooks/mutations/Appointments/useCreateAppointment";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { useGenerateTimeOptions } from "lib/hooks/shared/useGenerateTimeOptions";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { useLocation } from "react-router-dom";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { customStyles } from "lib/helpers/configs/customStyles";
import { languageData } from "lib/helpers/constants/languageData";
import Creatable from "react-select/creatable";
import { numbersCode } from "lib/helpers/constants/numbersCode";
import Flag from "react-world-flags";

export interface ICreateAppointmentProps {
  onConfirm: () => void;
  themeColors?: {
    primary: string;
  };
}

const CreateAppointment: FC<ICreateAppointmentProps> = ({
  onConfirm,
  themeColors,
}) => {
  const location = useLocation();
  const { mutateAsync: createAppointment, isLoading } = useCreateAppointments();
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const hasAdminPermissions = usePermissions([P["Appointment Admin"]]);
  const hasMenagerPermissions = usePermissions([P["Appointment Menager"]]);
  const defaultValue = numbersCode.find(item => item.value === '+41');

  const { timeOptions } = useGenerateTimeOptions();
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useAppointmentForm({
    defaultValues: {
      persons: 0,
    },
  });

  useEffect(() => {
    getUsersByPermission({
      permissions: [P["Appointment Salesman"]],
    })
      .then((res) => {
        setFetchedUsers(res.users);
      })
      .catch(() => {});
      // eslint-disable-next-line
  }, []);

  const onSubmit = (data: Record<string, any>) => {
    const combinedPhoneNumber = `${data.code} ${data.phone_number}`;
    createAppointment({
      ...data,
      phone_number: combinedPhoneNumber
    })
      .then(() => {
        onConfirm();
        reset();
      })
      .catch(() => {});
  };

  const formatOptionLabel = (data: any, { context }: any) => {
    if (context === "menu") {
      return (
        <div className="flex items-center">
          <Flag code={data.icon} height={14} width={14} />
          <span className="ml-2">{data.label}</span>
        </div>
      );
    } else {
      return (
        <div className="text-center mx-auto">
           {data.label}
        </div>
      )
    }
  };

  const [MapAddress, setMapAddress] = useState({
    postal_code: "",
    address: "",
    nr: "",
    city: "",
  });

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="mb-0 flex flex-col">
        <div className="flex flex-row relative gap-[55px]">
          <div className="w-full">
            <div className="flex flex-row gap-4 absolute right-0">
              <div
                className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min`}
              >
                <LocationIcon color="#fff" />
              </div>
              <div
                className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min`}
              >
                <EmailIcon color="#fff" />
              </div>
              <div
                className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min`}
              >
                <PhoneIcon color="#fff" />
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <InputsFolder
                title="Personal information"
                themeAsProps={themeColors}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                  <Input
                    label="First Name"
                    placeholder="First Name"
                    {...register("first_name")}
                    error={errors?.first_name}
                  />
                  <Input
                    label="Last Name"
                    placeholder="Last Name"
                    {...register("last_name")}
                    error={errors?.last_name}
                  />
                  <Input
                    label="Nationality"
                    placeholder="Nationality"
                    {...register("nationality")}
                    error={errors?.nationality}
                  />
                  <Controller
                    control={control}
                    name="birthdate"
                    rules={{ required: "Date of birth is required" }}
                    render={({ field: { onChange, value } }) => (
                      <PrimaryDatePicker
                        label="Date Of Birth"
                        startDate={value}
                        {...register("birthdate")}
                        setStartDate={(date: string) => onChange(date)}
                        error={errors.birthdate}
                      />
                    )}
                  />
                  <Input
                    label="Current Health Insurance"
                    placeholder="Current Health Insurance"
                    {...register("current_health_insurance")}
                    error={errors?.current_health_insurance}
                  />
                  <div>
                    <Label>Persons in Household</Label>
                    <div className="w-full">
                      <Controller
                        control={control}
                        name="persons"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <CounterControl
                            count={value}
                            className="!mt-0"
                            classes={{
                              container: "w-full bg-white !border-inputborder",
                            }}
                            onIncrement={() => onChange(value + 1)}
                            onDecrement={() =>
                              onChange(value >= 1 ? value - 1 : value)
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <Label text="Insurance Type" />
                    <Controller
                      name="insurance_type"
                      control={control}
                      render={({ field }) => (
                        <Creatable<any, false>
                          {...field}
                          placeholder="Choose insurance type"
                          options={[
                            {
                              value: "Health Insurance",
                              label: "Health Insurance",
                            },
                            {
                              value: "Physical",
                              label: "Physical",
                            },
                          ]}
                          value={
                            field.value
                              ? { value: field.value, label: field.value }
                              : null
                          }
                          className="cursor-pointer font-inter-regular react-select-container"
                          classNamePrefix="react-select"
                          onChange={(newValue: any, actionMeta: any) => {
                            if (
                              actionMeta.action === "select-option" &&
                              newValue?.value
                            ) {
                              field.onChange(newValue.value);
                            }
                          }}
                          styles={customStyles}
                          isValidNewOption={() => false}
                          menuPosition={"fixed"}
                          menuPortalTarget={document.body}
                        />
                      )}
                    />
                  </div>
                  <Input
                    label="Source"
                    placeholder="Company X"
                    {...register("source")}
                    error={errors?.source}
                  />
                </div>
              </InputsFolder>
              <InputsFolder
                title="Contact information"
                themeAsProps={themeColors}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                  <div className="relative">
                    <Label text="Language" />
                    <Controller
                      name="language"
                      control={control}
                      rules={{ required: "Language is required" }}
                      render={({ field }) => (
                        <Creatable<any, false>
                          {...field}
                          placeholder="Choose language"
                          options={languageData.map((language) => ({
                            value: language.value,
                            label: language.label,
                          }))}
                          value={
                            field.value
                              ? { value: field.value, label: field.value }
                              : null
                          }
                          className="cursor-pointer font-inter-regular react-select-container"
                          classNamePrefix="react-select"
                          onChange={(newValue: any, actionMeta: any) => {
                            if (
                              actionMeta.action === "select-option" &&
                              newValue?.value
                            ) {
                              field.onChange(newValue.value);
                            }
                          }}
                          styles={customStyles}
                          isValidNewOption={() => false}
                          menuPosition={"fixed"}
                          menuPortalTarget={document.body}
                        />
                      )}
                    />
                    {errors.language?.message && (
                      <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                        <>{errors.language?.message}</>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-3">
                    <div className="grid grid-cols-[100px,auto] gap-[16px] items-end w-full">
                      <Controller
                        name="code"
                        control={control}
                        defaultValue={defaultValue?.value} 
                        render={({ field }) => (
                          <Creatable<any, false>
                            {...field}
                            options={numbersCode.map((item) => ({
                              value: item.value,
                              label: item.label,
                              icon: item.icon
                            }))}
                            value={
                              field.value
                                ? numbersCode?.find(item => item.value === field.value)
                                : defaultValue || null
                            }
                            className="cursor-pointer font-inter-regular react-select-container"
                            classNamePrefix="react-select"
                            onChange={(newValue: any, actionMeta: any) => {
                              if (
                                actionMeta.action === "select-option" &&
                                newValue?.value
                              ) {
                                field.onChange(newValue.value);
                              }
                            }}
                            styles={customStyles}
                            isValidNewOption={() => false}
                            menuPosition={"fixed"}
                            menuPortalTarget={document.body}
                            formatOptionLabel={formatOptionLabel}
                          />
                        )}
                      />
                      <Input
                        placeholder="Enter phone number"
                        {...register("phone_number")}
                        error={errors?.phone_number}
                        // className={`border ${errors.phone ? 'border-red-500' : watch("phone") ? 'border-[#66BB6A]' : 'border-inputborder'}`}
                      />
                    </div>
                  </div>

                  <div className="flex gap-3">
                    <div className="flex-1">
                      <Input
                        placeholder="Enter email"
                        label="Email"
                        {...register("email")}
                        error={errors?.email}
                      />
                    </div>
                    <div className="flex-none pt-[40px]">
                      <EmailIcon />
                    </div>
                  </div>
                </div>
              </InputsFolder>
              <InputsFolder
                title="Appointment details"
                themeAsProps={themeColors}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pb-2">
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: "Date of appointment is required" }}
                    render={({ field: { onChange, value } }) => (
                      <PrimaryDatePicker
                        label="Appointment Date"
                        startDate={value}
                        {...register("date")}
                        setStartDate={(date: string) => onChange(date)}
                        error={errors?.date}
                      />
                    )}
                  />
                  <div className="relative">
                    <Label text="Appointment Time" />
                    <Controller
                      name="time"
                      control={control}
                      
                      render={({ field }) => (
                        <Creatable<any, false>
                          {...field}
                          placeholder="Choose appointment time"
                          options={timeOptions.map((language) => ({
                            value: language.value,
                            label: language.label,
                          }))}
                          value={
                            field.value
                              ? { value: field.value, label: field.value }
                              : null
                          }
                          className="cursor-pointer font-inter-regular react-select-container"
                          classNamePrefix="react-select"
                          onChange={(newValue: any, actionMeta: any) => {
                            if (
                              actionMeta.action === "select-option" &&
                              newValue?.value
                            ) {
                              field.onChange(newValue.value);
                            }
                          }}
                          styles={customStyles}
                          isValidNewOption={() => false}
                          menuPosition={"fixed"}
                          menuPortalTarget={document.body}

                        />
                      )}
                    />
                   {errors.time?.message && (
                      <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-16px]  left-[2px]">
                        <>{errors.time?.message}</>
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-3">
                    <Input
                      placeholder="Enter street"
                      label="Street"
                      icon={IconType.LOCATION_ICON}
                      classes={{ icon: "!bg-white right-[8px]" }}
                      {...register("address")}
                      error={errors?.address}
                      onChange={(e) => {
                        setMapAddress({
                          ...MapAddress,
                          address: e.target.value,
                        });
                      }}
                    />
                    <Input
                      placeholder="Enter Nr"
                      label="No"
                      {...register("nr")}
                      error={errors?.nr}
                      onChange={(e) => {
                        setMapAddress({ ...MapAddress, nr: e.target.value });
                      }}
                    />
                    <Input
                      placeholder="ZIP"
                      label="PLZ"
                      {...register("postal_code")}
                      error={errors?.postal_code}
                      onChange={(e) => {
                        setMapAddress({
                          ...MapAddress,
                          postal_code: e.target.value,
                        });
                      }}
                    />
                    <Input
                      placeholder="City"
                      label="City"
                      {...register("city")}
                      error={errors?.city}
                      onChange={(e) => {
                        setMapAddress({ ...MapAddress, city: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <iframe
                      className="border-inputborder border rounded-[8px]"
                      title="map"
                      src={`https://maps.google.com/maps?q='+${MapAddress.postal_code} ${MapAddress.address} ${MapAddress.city}  ${MapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                      style={{ width: "100%", height: "100%" }}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </InputsFolder>
              <InputsFolder themeAsProps={themeColors} title="Related details">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                  <div>
                    <Label>Note</Label>
                    <TextArea
                      {...register("note")}
                      rows={4}
                      className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                      placeholder="Write note"
                      error={errors?.note}
                    />
                  </div>
                  <ConditionalRender
                    condition={
                      hasAdminPermissions ||
                      (hasMenagerPermissions &&
                        location.pathname === "/appointments")
                    }
                  >
                    <div>
                      <div className="relative">
                        <Label text="Assigned to" />
                        <Controller
                          name="assigned_to"
                          control={control}
                          rules={{ required: "Assigned to is required" }}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              placeholder="Assign to"
                              options={
                                (
                                  fetchedUsers as unknown as Array<{
                                    name: string;
                                    id: string;
                                  }>
                                )?.map?.((user) => ({
                                  label: user?.name,
                                  value: user?.id,
                                })) ?? []
                              }
                              value={
                                field?.value
                                  ? {
                                      value: field?.value,
                                      label:
                                        (
                                          fetchedUsers?.find?.(
                                            (user: any) =>
                                              user.id === field.value
                                          ) as { name: string } | undefined
                                        )?.name || field.value,
                                    }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
                    </div>
                  </ConditionalRender>
                </div>
              </InputsFolder>
              <div className="flex justify-end gap-[26px]">
                <IconButton
                  disabled={isLoading ? true : false}
                  icon={<Icon iconType={IconType.PLUS_ICON} />}
                >
                  {isLoading ? "Creating..." : "Create Appointment"}
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateAppointment;
