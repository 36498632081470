import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Icon from "components/atoms/icon/Icon";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Dispatch, FC } from "react";

export interface IChooseProductsProps<T> {
  data?: T;
  setFormStep?: (value: number) => void;
  setFormData?: Dispatch<any>;
  themeColors: {
    primary: string;
  };
  onSubmitFormData?: any;
  create: (data: any) => void;
  onConfirm?: () => void;
  productsFormData: any;
}

const ChooseProducts: FC<IChooseProductsProps<any>> = ({
  setFormStep,
  data,
  setFormData,
  themeColors,
  onSubmitFormData,
  create,
  onConfirm,
  productsFormData
}) => {
  const onSubmitOfForm = () => {
    create({
      ...onSubmitFormData,
      ...productsFormData
    });
    onConfirm && onConfirm();
  };

  return (
    <div>
      <NoDataBoundary
        condition={data?.length >= 1}
        fallback={<EmptyEntity name="Products" />}
      >
        <InputsFolder title="Products" childrenClassName="flex flex-col gap-5">
          <>
            {data?.map?.((product: any, index: number) => (
              <div
                key={`product-key-${index}`}
                className="flex items-center justify-between bg-white border-[1px] text-[#667085]  text-[14px] border-inputborder rounded-[8px] w-full p-[12px]  font-inter-regular"
              >
                <div className="flex items-center gap-[10px]">
                  <Icon iconType={IconType.KVG_ICON} />
                  <span className="text-[#282D46] font-inter-medium capitalize">
                    {product.name_of_products} ({product.company_of_product})
                  </span>
                </div>
                <Button
                  onClick={() => {
                    setFormData &&
                      setFormData((prevData: any) => {
                        if (prevData && Array.isArray(prevData.products)) {
                          const newProducts = prevData.products.filter(
                            (_: unknown, i: number) => i !== index
                          );
                          return { ...prevData, products: newProducts };
                        }
                        return prevData;
                      });
                  }}
                >
                  <Icon iconType={IconType.DELETE_ICON} color="#979797" />
                </Button>
              </div>
            ))}
          </>
        </InputsFolder>
      </NoDataBoundary>
      <div className="flex justify-end gap-[26px] mt-5">
        <Button
          onClick={() => setFormStep && setFormStep(2)}
          type="button"
          className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme]`}
        >
          <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
          Add Product
        </Button>
        <IconButton
          onClick={() => onSubmitOfForm()}
          type="button"
          icon={
            <Icon iconType={IconType.PLUS_ICON} color='white' />
          }
        >
          Create
        </IconButton>
      </div>
    </div>
  );
};

export default ChooseProducts;
