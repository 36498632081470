import { FC, useState } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import CSVUpload from "./upload-appointment-steps/CSVUpload";
import ChooseFields from "./upload-appointment-steps/ChooseFields";
import StoreFields from "./upload-appointment-steps/StoreFields";
import Congrats from "./upload-appointment-steps/Congrats";
import withModal from "lib/helpers/hoc/withModal";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";

export interface IUploadAppointmentProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  setCurrentStep: (args: number) => void;
  currentStep: any;
}

const UploadAppointment: FC<IUploadAppointmentProps> = ({
  onConfirm,
  themeColors,
  setCurrentStep,
  currentStep
}) => {
  const uploadAppointmentCols = [
    {
      id: 1,
      Header: "First Name",
      accessor: "first_name",
      isChecked: true,
    },
    {
      id: 2,
      Header: "Last Name",
      accessor: "last_name",
      isChecked: true,
    },
    {
      id: 3,
      Header: "Date",
      accessor: "date",
      isChecked: true,
    },
    {
      id: 4,
      Header: "Time",
      accessor: "time",
      isChecked: true,
    },
    {
      id: 5,
      Header: "Date Of Birth",
      accessor: "birthdate",
      isChecked: true,
    },
    {
      id: 6,
      Header: "Phone",
      accessor: "phone_number",
      isChecked: true,
    },
    {
      id: 7,
      Header: "Persons in Household",
      accessor: "persons",
      isChecked: true,
    },
    {
      id: 8,
      Header: "Nationality",
      accessor: "nationality",
      isChecked: true,
    },
    {
      id: 9,
      Header: "Language",
      accessor: "language",
      isChecked: true,
    },
    {
      id: 10,
      Header: "Street",
      accessor: "address",
      isChecked: true,
    },
    {
      id: 11,
      Header: "Nr",
      accessor: "nr",
      isChecked: true,
    },
    {
      id: 12,
      Header: "PLZ",
      accessor: "postal_code",
      isChecked: true,
    },
    {
      id: 13,
      Header: "City",
      accessor: "city",
      isChecked: true,
    },
    {
      id: 14,
      Header: "Email",
      accessor: "email",
      isChecked: true,
    },
    {
      id: 15,
      Header: "Current Health Insurance",
      accessor: "current_health_insurance",
      isChecked: true,
    },
    {
      id: 16,
      Header: "Insurance Type",
      accessor: "insurance_type",
      isChecked: true,
    },
    {
      id: 17,
      Header: "Note",
      accessor: "note",
      isChecked: true,
    },
  ];
  const handleFormValuesChange = (step: number, values: any) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const [formValues, setFormValues] = useState<Array<string>>([]);
  
  // eslint-disable-next-line
  const [cardsOrder, _] = useState(uploadAppointmentCols);

  const handleUpdateData = (updatedData: any[]) => {
    setFormValues(updatedData);
  };

  return (
    <div className="mb-0 flex flex-col gap-7">
   <ConditionalRender condition={currentStep >= 2 && currentStep !== 4}>
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }}
        >
          <Icon
            className={currentStep >= 2 && currentStep < 6 ? "block" : "hidden"}
            iconType={IconType.BACK_BUTTON_MODAL}
            color={themeColors.primary}
          />
        </button>
      </ConditionalRender>

      {/* {
        {
          1: (
            <CSVUpload
              setStep={setStep}
              handleFormValuesChange={handleFormValuesChange}
              formValues={formValues}
              themeColors={themeColors}
            />
          ),
          2: <ChooseFields setStep={setStep} themeColors={themeColors} />,
          3: (
            <StoreFields
              setStep={setStep}
              dataCols={cardsOrder}
              onUpdateData={handleUpdateData}
              themeColors={themeColors}
            />
          ),
          4: <Congrats onConfirm={onConfirm} themeColors={themeColors} />,
        }[step]
      } */}
      <ConditionalRender condition={currentStep === 1}>
        <CSVUpload
          setStep={setCurrentStep}
          handleFormValuesChange={handleFormValuesChange}
          formValues={formValues}
          themeColors={themeColors}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <ChooseFields setStep={setCurrentStep} themeColors={themeColors} />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <StoreFields
          setStep={setCurrentStep}
          dataCols={cardsOrder}
          onUpdateData={handleUpdateData}
          themeColors={themeColors}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <Congrats onConfirm={onConfirm} themeColors={themeColors} setCurrentStep={setCurrentStep} />
      </ConditionalRender>
    </div>
  );
};

export default withModal(UploadAppointment);
