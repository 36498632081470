import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import CampaignGrid from "./campaign-grid/CampaignGrid";
import CreateCampaign from "./create-campaign/CreateCampaign";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import LeadsCards from "../leads/leads-cards/LeadsCards";
import { useCampaigns } from "lib/hooks/queries/Campaign/useCampaigns";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import CampaignList from "./campaign-list/CampaignList";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import FilterTableDropdown from "../leads/dropdowns/FilterCampaign";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { campaignHeaders } from "lib/helpers/constants/columns/campaignHeaders";

export interface ICampaignProps { }

const Campaign: FC<ICampaignProps> = () => {
  const [ActiveView, setActiveView] = useState<string>("listView");
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const { themeColors } = useThemeContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [filterData, setFilterData] = useState<any>({
    search: "",
    status: "",
    date_of_creation: undefined
  });
  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = useCampaigns(
    filterData.search,
    filterData.status,
    filterData.date_of_creation
  );

  const [cardsOrder, setCardsOrder] = useState(campaignHeaders);

  return (
    <>
      <Headline title="Campaigns" />

      <LeadsCards />
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`Campaigns (${data?.pages?.[0]?.data?.data?.length ?? ""
                })`}
            />
          </div>
          <IconButton
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            New Campaign
          </IconButton>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder="Search"
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  search: e.target.value
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex-none ">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title="Configure Table"
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title="Filter"
                    className="sm:!w-[350px]"
                  >
                    <FilterTableDropdown
                      setFilterData={setFilterData}
                      filterData={filterData}
                      setIsOpen={setFilterTableOpen}
                      themeColors={themeColors}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={ActiveView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={ActiveView === "kundenView"}>
          <NoDataBoundary
            condition={data?.pages?.[0]?.data?.data?.length > 0}
            fallback={<EmptyEntity name="Campaigns" />}
          >
            <CampaignGrid
              data={data?.pages?.[0]?.data?.data ?? []}
              openNewCampaignModal={() => {
                setIsModalOpen(true);
              }}
            />
          </NoDataBoundary>
        </ConditionalRender>
        <ConditionalRender condition={ActiveView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Campaigns" />}
            >
              <CampaignList
                key={currentPage}
                data={data?.pages?.[0]?.data?.data ?? []}
                dataCols={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <ConditionalRender condition={!!data}>
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.data?.from}
          to={data?.pages[0]?.data?.to}
          total={data?.pages[0]?.data?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      <CreateCampaign
        clearData={true}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        hocClasses={{
          modalClassName:
            currentStep === 1
              ? "w-full min-w-[551px] max-w-[551px]"
              : currentStep === 2
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : currentStep === 6
                  ? "w-full md:max-w-[700px]  lg:min-w-[762px] max-w-[762px]"
                  : currentStep === 7
                    ? "w-full sm:max-w-[551px] md:max-w-[551px]  lg:min-w-[551px] max-w-[551px]"
                    : currentStep === 8
                      ? "!max-w-[462px] w-full"
                      : "w-full md:md:max-w-[700px] lg:min-w-[1008px] max-w-[1008px]",
          titleClassName: currentStep === 1 ? "" : currentStep === 7 ? 'ml-0' : "ml-[35px]"
        }}
        onConfirm={() => {
          setIsModalOpen(false);
        }}
        hocTitle={
          currentStep === 1
            ? "Create New Campaign"
            : currentStep === 2
              ? "Create New Campaign"
              : currentStep === 3
                ? "Upload CSV"
                : currentStep === 4
                  ? "Upload CSV"
                  : currentStep === 5
                    ? "Upload CSV"
                    : currentStep === 7 ? "Create New Source" : ""
        }
        themeColors={themeColors}
        hocCurrentStep={currentStep}
        hocToggleModal={
          isModalOpen
            ? () => {
              setIsModalOpen(false);
            }
            : () => {
              setIsModalOpen(true);
            }
        }
        hocisOpen={isModalOpen}
        setHocCurrentStep={setCurrentStep}
        hocHasChanges={false}
      />
    </>
  );
};

export default Campaign;
