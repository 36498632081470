import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { FC, useLayoutEffect } from "react";
import ExportCustomers from "./ExportCustomers";
import IconButton from "components/atoms/button/IconButton";

interface IExportCustomersProcessProps {
  onConfirm: () => void;
  onCancel: () => void;
  themeColors: {
    primary: string;
  };
}

const ExportCustomersProcess: FC<IExportCustomersProcessProps> = ({
  onConfirm,
  onCancel,
  themeColors,
}) => {
  const { currentStep, setCurrentStep } = useMultiStepFormContext();

  useLayoutEffect(() => {
    setCurrentStep(1);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {
        {
          1: (
            <div>
              <div className="mb-0 flex flex-col gap-[37px]">
                <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[5px] text-[#282D46] font-inter-semibold text-[22px]">
                  Send Request To Admin
                </h1>
                <p className="flex flex-col   font-inter-medium text-[#282D46] mt-[10px] text-[20px]">
                  You need to send a request for export to the administrator so
                  you can export customer’s list.
                </p>
                <div className="flex justify-end gap-5">
                  <IconButton
                    secondary={true}
                    onClick={onCancel}
                    className={`w-full max-w-[150px] text-[13px] font-inter-bold`}
                  >
                    Cancel
                  </IconButton>
                  <IconButton
                    onClick={() => setCurrentStep(2)}
                    className="w-full max-w-[150px]  text-center !text-white border-primary border rounded-[8px] py-2 lg:py-3 px-5  font-inter-medium text-sm xl:text-base "
                  >
                    Send request
                  </IconButton>
                </div>
              </div>
            </div>
          ),
          2: (
            <ExportCustomers onConfirm={onConfirm} themeColors={themeColors} />
          ),
        }[currentStep]
      }
    </div>
  );
};

export default ExportCustomersProcess;
