import { sendLeadNote } from "api/Leads";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useLeadNote = () => {
  
  const queryClient = useQueryClient();

  return useMutation(sendLeadNote, {
    onSuccess: () => {

    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
