import Button from "components/atoms/button/Button";
import Form from "components/atoms/form/Form";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  options,
  uploadfieldTypesOptions
} from "lib/helpers/constants/optionsOfExtraFieldToLeads";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ErrorState from "components/atoms/error-state/ErrorState";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";

export interface IProps {
  themeColors: {
    primary: string;
  };
  setFormCurrentStep: any;
  state: any;
  setState: any;
}

const ExtraFields: FC<IProps> = ({
  themeColors,
  setFormCurrentStep,
  state,
  setState
}) => {
  const [localAddNewSection, setAddNewLocalSection] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    register: extraFieldRegister,
    handleSubmit: handleSubmitNested,
    reset: resetExtraFields,
    control,
    formState: { errors: extraFieldsErrors }
  } = useForm();

  const onSubmitExtraFields = (data: any) => {
    const isDuplicate = state.some(
      (item: any) => item.data.field_name === data.field_name
    );

    if (isDuplicate) {
      setErrorMessage("Duplicate field name is not allowed");
      return;
    }
    setFormCurrentStep(2);
    setState((prevState: any) => [...prevState, { data }]);
    resetExtraFields({
      section: "",
      field_name: "",
      field_type: "",
      default_text: "",
      required: false
    });
    setAddNewLocalSection(false);
  };
  return (
    <div>
      <>
        <Form onSubmit={handleSubmitNested(onSubmitExtraFields)}>
          <Button
            onClick={() => setFormCurrentStep(1)}
            type="button"
            className={`absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]`}
          >
            <Icon
              iconType={IconType.BACK_BUTTON_MODAL}
              className="!w-[30px] !h-[30px]"
              color={themeColors.primary}
            />
            Create New Field
          </Button>
          {localAddNewSection ? (
            <Input
              {...extraFieldRegister("section")}
              label="Section Name"
              className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
              placeholder="Write note"
              error={extraFieldsErrors?.section}
            />
          ) : (
            <>
              <div className="relative">
                <Label text="Section" />
                <Controller
                  name="section"
                  control={control}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      placeholder="Choose a  section"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === field.value
                        ) || ""
                      }
                      className="cursor-pointer font-inter-regular react-select-container"
                      classNamePrefix="react-select"
                      onChange={(newValue: any, actionMeta: any) => {
                        if (
                          actionMeta.action === "select-option" &&
                          newValue?.value
                        ) {
                          field.onChange(newValue.value);
                        }
                      }}
                      styles={customStyles}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
              </div>
            </>
          )}
          <Button
            onClick={() => setAddNewLocalSection(true)}
            type="button"
            className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme] mt-5 ml-auto`}
          >
            <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
            Add new section
          </Button>
          <div className="flex flex-col gap-[15px]">
            <Input
              {...extraFieldRegister("field_name", {
                required: "Field Name is required"
              })}
              label="Field Name*"
              className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
              placeholder="Write note"
              error={extraFieldsErrors?.field_name}
            />
            <div className="relative">
              <Label text="Field Type*" />
              <Controller
                name="field_type"
                control={control}
                rules={{ required: "Field Type is required" }}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder="Choose Field Type"
                    options={uploadfieldTypesOptions}
                    value={
                      uploadfieldTypesOptions.find(
                        (option) => option.value === field.value
                      ) || ""
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              {extraFieldsErrors.field_type?.message && (
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  <>{extraFieldsErrors.field_type?.message}</>
                </div>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-[40px]">
              <IconButton
                type="button"
                onClick={() => {
                  resetExtraFields({
                    section: "",
                    field_name: "",
                    field_type: "",
                    required: false
                  });
                  setAddNewLocalSection(false);
                }}
                secondary={true}
                className="w-full max-w-[150px] !bg-transparent"
              >
                Reset
              </IconButton>
              <IconButton
                type="submit"
                icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
                className={`w-full max-w-[150px]  text-white !border-[--theme]`}
              >
                Create
              </IconButton>
            </div>
          </div>
          <ErrorState error={errorMessage}>{errorMessage}</ErrorState>
        </Form>
      </>
    </div>
  );
};

export default ExtraFields;