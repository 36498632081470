import React, { FC, HTMLAttributes, useEffect, useState } from "react";

export interface ILeadStatusSelectProps<T>
  extends HTMLAttributes<HTMLSelectElement> {
  status: any;
  data: T;
}

const LeadStatusSelect: FC<ILeadStatusSelectProps<any>> = ({
  status,
  data
}) => {
  const [Status, setStatus] = useState<string>(status);

  const [Style, setStyle] = useState({ color: "", backgroundColor: "" });

  useEffect(() => {
    setStatus(status);
  }, [status]);

  useEffect(() => {
    switch (Status) {
      case "Unprocessed":
        setStyle({ color: "#DE92EB", backgroundColor: "#DE92EB08" });
        break;
      case "Not Reached":
        setStyle({ color: "#CC7272", backgroundColor: "#CC727208" });
        break;
      case "In Contact":
        setStyle({ color: "#EFBE12", backgroundColor: "#EFBE1208" });
        break;
      case "Appointment":
        setStyle({ color: "#70DDE4", backgroundColor: "#70DDE408" });
        break;
      case "New Customer":
        setStyle({ color: "#2DE28B", backgroundColor: "#2DE28B08" });
        break;
      case "Lost":
        setStyle({ color: "#DC4D4D", backgroundColor: "#DC4D4D08" });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  

  return (
    <>
      {data.assigned_to === null ? (
        ""
      ) : (
        <span className="w-auto rounded-[8px] font-inter-light px-2 py-1 outline-none text-[14px] md:text-[16px]" style={{ color: Style.color, backgroundColor: Style.backgroundColor }}>
          {Status}
        </span>

      )}
    </>
  );
};

export default LeadStatusSelect;
