import { FC, useState, useEffect } from "react";
import Paragraph from "../../atoms/paragraph/Paragraph";
import ToggleSwitch from "../toggle-switch/ToggleSwitch";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { IModulesResponse } from "../../../lib/types/permission.module.types";
import Accordion from "../accordion/Accordion";
import AccordionTitle from "../accordion/AccordionTitle/AccordionTitle";
import AccordionBody from "../accordion/AccordionBody/AccordionBody";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";

interface IModuleSelectorProps {
  data: IModulesResponse;
  control: Control;
  error?: DeepMap<any, FieldError>;
  setValue: (name: string, value: any) => void;
  clearErrors: (name?: string | string[]) => void;
  getValues: (name?: string | string[]) => any;
}

const ModuleSelector: FC<IModuleSelectorProps> = ({
  control,
  data,
  error,
  setValue,
  clearErrors,
  getValues,
}) => {
  const { store } = useSignupCustomHooks({ initalStep: undefined });
  
  // eslint-disable-next-line 
  const activeModules = store.modules || [];
  const [noModulesSelected, setNoModulesSelected] = useState(activeModules.length === 0);

  useEffect(() => {
    setNoModulesSelected(activeModules.length === 0);
  }, [activeModules]);

  return (
    <div>
      <Paragraph
        paragraph="Modules"
        className="text-primaryTextColor !text-[14px] font-inter-regular"
      >
        <span className="text-primaryTextColor text-[13px] block">
          Select the modules you want to use in your system.
        </span>
      </Paragraph>
      {data?.modules?.map((item, index) => {
        const isActive = getValues("modules")?.includes(item?.id.toString());
        return (
          <div
            key={`permissions-module-key-${item.id}`}
            className="mt-[24px] relative"
          >
            <Accordion>
              <AccordionTitle>{item.name}</AccordionTitle>
              <AccordionBody>
                {item.description}
                <span>{item.price}CHF</span>
              </AccordionBody>
            </Accordion>
            <Controller
              name={`${item.id}`}
              control={control}
              defaultValue={isActive}
              render={({ field }) => {
                return (
                  <ToggleSwitch
                    checked={field.value ? field.value : false}
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      field.onChange(newValue);
                      const updatedModules = newValue
                        ? [...activeModules, item.id.toString()]
                        : activeModules.filter(
                            (id: string) => id !== item.id.toString()
                          );
                      setValue("modules", updatedModules);
                      clearErrors("modules");
                    }}
                    className="!absolute right-[-55px] top-[10px]"
                    error={noModulesSelected ? error?.modules : null}
                  />
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ModuleSelector;