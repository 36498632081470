import { sendAppointmentNote } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useAppointmentNote = () => {

    const queryClient = useQueryClient();

    return useMutation(sendAppointmentNote, {
        onSuccess: () => {
    
        },
        onError: () => {
            toast.error(`Something went wrong`);
        },
    });
};
