import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check2Icon from "components/atoms/icon/icon-type/Check2Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useUploadAppointmentDataOfCsv } from "lib/hooks/mutations/Appointments/useUploadAppointmentDataOfCsv";
import { FC, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";

export interface IProps {
  setStep: (value: number) => void;
  themeColors: {
    primary: string;
  };
}

const fields = [
  { label: "First Name*", value: "first_name", required: true },
  { label: "Last Name*", value: "last_name", required: true },
  { label: "Date*", value: "date", required: true },
  { label: "Time*", value: "time", required: true },
  { label: "Date Of Birth", value: "birthdate" },
  { label: "Phone*", value: "phone_number", required: true },
  { label: "Persons in Household", value: "persons" },
  { label: "Nationality", value: "nationality" },
  { label: "Language", value: "language" },
  { label: "Street", value: "address" },
  { label: "Nr", value: "nr" },
  { label: "PLZ", value: "postal_code" },
  { label: "City", value: "city" },
  { label: "Email", value: "email" },
  { label: "Current Health Insurance", value: "current_health_insurance" },
  { label: "Insurance Type", value: "insurance_type" },
  { label: "Note", value: "note" },
];

const ChooseFields: FC<IProps> = ({ setStep, themeColors }) => {
  const { formData } = useMultiStepFormContext();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    trigger,
    reset,
    clearErrors,
    setError,
  } = useForm();

  const headers = (formData as { headers: string[] })?.headers;

  const { mutateAsync: dataCsv, isLoading } = useUploadAppointmentDataOfCsv();

  const options = headers?.map?.((header: any) => ({
    label: header,
    value: header.toLowerCase().replace(/\s+/g, "_"),
  }));

  const onSelectChange = (fieldName: string, selectedIndex: number) => {
    if (!fieldName) return;
    setValue(String(fieldName), selectedIndex);
  };

  const onSubmit = async (data: any) => {
    const fileFromFormData = formData as any;

    const updatedData = { ...data, file: fileFromFormData.file };

    dataCsv(updatedData)
      .then(() => {
        setStep(3);
      })
      .catch(() => {});
  };

  const customSelectRefs = useRef<(Creatable | null)[]>([]);

  const handleReset = async () => {
    customSelectRefs.current.forEach((ref: any) => ref?.clearValue());

    reset();

    const fieldNames = fields.map((field) => field.value);
    await trigger(fieldNames);

    fields.forEach((field) => {
      if (field?.required) {
        setError(field?.value, {
          type: "manual",
          message: `${field?.label} is required`,
        });
      }
    });
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col pl-4 pb-2"
    >
      {/* Upload CSV */}
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5">
          Upload CSV
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      {/* Adjust Fields */}
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          Only adjust the fields you want to import
        </div>
        <div
          style={{
            backgroundColor: `${themeColors.primary}0D`,
          }}
          className={` rounded-[20px] p-7 grid grid-cols-1 lg:grid-cols-2 gap-4`}
        >
          {fields.map((field, index) => (
            <div key={index}>
              <div>
                <Label className="capitalize">{field.label}</Label>
              </div>
              <div className="flex flex-row items-center gap-2 relative">
                <div className="flex-1">
                  <Controller
                    name={field?.value}
                    rules={{ required: field.required }}
                    control={control}
                    render={({ field }) => {
                      const selectedOption =
                        options?.find(
                          (option) => option?.value === field?.value
                        ) || null;

                      return (
                        <Creatable
                          {...field}
                          ref={(el: any) =>
                            (customSelectRefs.current[index] = el)
                          }
                          placeholder="Choose Field Name"
                          options={options}
                          value={selectedOption?.label}
                          className="cursor-pointer !font-inter-regular react-select-container"
                          classNamePrefix="react-select"
                          isValidNewOption={() => false}
                          onChange={(selectedOption) => {
                            const selectedIndex =
                              options &&
                              options?.findIndex?.(
                                (option) =>
                                  option?.value === selectedOption?.value
                              );
                            field.onChange(
                              selectedOption ? selectedOption?.value : null
                            );
                            clearErrors(field.name);
                            onSelectChange(field.name, selectedIndex);
                          }}
                          styles={customStyles}
                          menuPosition={"fixed"}
                          menuPortalTarget={document.body}
                        />
                      );
                    }}
                  />
                  {errors[field.value] && (
                    <p className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">{`${field.label} is required`}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
          {isLoading && (
            <LoadingScreen className="!h-full w-fit left-[2px]  top-[20px] !z-0" />
          )}{" "}
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <Check2Icon color={themeColors.primary} />
        </div>
      </div>
      {/* Remove Leads */}
      <div className="pl-7 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 opacity-40">
          Remove the appointments here that you don't want to import
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <Check3Icon className="opacity-40" color={themeColors.primary} />
        </div>
      </div>
      {/* Buttons */}
      <div className="flex gap-5 justify-end">
        <IconButton
          type="button"
          onClick={handleReset}
          secondary={true}
          className="w-full max-w-[150px]"
        >
          Reset
        </IconButton>
        <IconButton
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          className="w-full max-w-[150px]"
          disabled={isLoading ? true : false}
        >
          {isLoading ? "Processing..." : "Continue"}
        </IconButton>
      </div>
    </Form>
  );
};
export default ChooseFields;
