import { FC, useState } from "react";
import Step1 from "./create-campaign-steps/Step1";
import Step2 from "./create-campaign-steps/Step2";
import Step3 from "./create-campaign-steps/Step3";
import Step4 from "./create-campaign-steps/Step4";
import Step5 from "./create-campaign-steps/Step5";
import Step6 from "./create-campaign-steps/Step6";
import { createCampaignTableHeaders } from "lib/helpers/constants/columns/createCampaignTableHeaders";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import NewSourceModal from "./new-source-modal/NewSourceModal";
import ExtraFields from "./create-campaign-steps/ExtraFields";

export interface ICreateCampaignProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  setCurrentStep: (args: number) => void;
  currentStep: number;
}

const CreateCampaign: FC<ICreateCampaignProps> = ({
  onConfirm,
  themeColors,
  currentStep,
  setCurrentStep
}) => {
  const handleFormValuesChange = (step: number, values: any) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const [FormValues, setFormValues] = useState<Array<string>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cardsOrder, setCardsOrder] = useState(createCampaignTableHeaders);

  const handleUpdateData = (updatedData: any[]) => {
    setFormValues(updatedData);
  };

  const [state, setState] = useState<any>([]);

  return (
    <div className="mb-0 flex flex-col gap-7">
      <ConditionalRender condition={currentStep >= 2 && currentStep !== 6}>
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            if (currentStep === 8) {
              setCurrentStep(4);
            } else {
              setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
            }
          }}
        >
          <Icon
            className={currentStep >= 2 && currentStep < 6 ? "block" : "hidden"}
            iconType={IconType.BACK_BUTTON_MODAL}
            color={themeColors.primary}
          />
        </button>
      </ConditionalRender>

      <ConditionalRender condition={currentStep === 1}>
        <Step1
          themeColors={themeColors}
          setStep={setCurrentStep}
          handleFormValuesChange={handleFormValuesChange}
          FormValues={FormValues}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <Step2 themeColors={themeColors} setStep={setCurrentStep} />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <Step3
          themeColors={themeColors}
          setStep={setCurrentStep}
          handleFormValuesChange={handleFormValuesChange}
          formValues={FormValues}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <Step4 themeColors={themeColors} setStep={setCurrentStep} setState={setState} state={state} />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 5}>
        <Step5
          themeColors={themeColors}
          setStep={setCurrentStep}
          dataCols={cardsOrder}
          setDataCols={setCardsOrder}
          onUpdateData={handleUpdateData}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 6}>
        <Step6 onConfirm={onConfirm} themeColors={themeColors} />
      </ConditionalRender>
      {/* ATTENTION THIS ONE OVER HERE OPENS CREATE SOURCE MODAL */}
      <ConditionalRender condition={currentStep === 7}>
        <NewSourceModal
          themeColors={themeColors}
          onConfirm={() => setCurrentStep(6)}
          setStep={setCurrentStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 8}>
        <ExtraFields
          themeColors={themeColors}
          setFormCurrentStep={setCurrentStep}
          state={state}
          setState={setState}
        />
      </ConditionalRender>
    </div>
  );
};

export default withModal(CreateCampaign);
